<p class="flex-center-distributed">
    <span class="grow"><h4>{{'CLIENT_CREDENTIALS.TITLE'|translate}}</h4></span>
    <button class="btn btn-agree" [disabled]="processing" (click)="create()">
        {{'CLIENT_CREDENTIALS.GENERATE'|translate}}
    </button>
</p>

<ng-container *ngIf="clientCredentials; else loading">
    <table class="table" [sortable]="clientCredentials" defaultSortBy="-created_at" *ngIf="clientCredentials?.length > 0; else noData" [sortable]="clientCredentials"
        defaultSortBy="-created_at" #tableSortable="tableSortable">
        <!-- <caption></caption> -->
        <thead>
            <tr>
                <th class="sap" sortBy="created_at">{{ 'TEMPLATES_VIEW.TABLE_HEADER.DATE'|translate }}</th>
                <th>Client ID</th>
                <th class="sap"></th>
                <th>Client Secret</th>
                <th class="sap"></th>
                <th class="sap"></th>
                <th class="sap"></th>
            </tr>
        </thead>
        <tbody>
            <tr class="tr-align-center" *ngFor="let credential of tableSortable.collection">
                <td class="sap small">
                    <date-md [date]="credential.created_at"></date-md>
                </td>
                <!-- Client id -->
                <td>
                    <input class="form-control credential" [readonly]="true" [value]="credential.client_id" />
                </td>
                <td class="sap">
                    <a (click)="clipboardService.copy(credential.client_id)"
                        title="{{ 'GLOBAL.COPY_CLIPBOARD'|translate }}">
                        <span class="material-symbols-rounded">content_copy</span>
                    </a>
                </td>
                <!-- Client Secret -->
                <td>
                    <input class="form-control credential" [readonly]="true" [type]="getType(credential.client_secret)"
                        [value]="credential.client_secret" />
                </td>
                <td class="sap">
                    <a (click)="toggleType(credential.client_secret)">
                        <span *ngIf="getType(credential.client_secret) === 'password'"
                            class="material-symbols-rounded">visibility</span>
                        <span *ngIf="getType(credential.client_secret) === 'text'"
                            class="material-symbols-rounded">visibility_off</span>
                    </a>
                </td>
                <td class="sap">
                    <a (click)="clipboardService.copy(credential.client_secret)"
                        title="{{ 'GLOBAL.COPY_CLIPBOARD'|translate }}">
                        <span class="material-symbols-rounded">content_copy</span>
                    </a>
                </td>
                <td class="sap">
                    <a type="button" class="text-danger" [disabled]="processing"
                        (onConfirm)="delete(credential, item)">
                        <span class="material-symbols-rounded">delete</span>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</ng-container>

<ng-template #createdCredentialsModal>
    <div class="modal-header">
        <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">{{ 'CLIENT_CREDENTIALS.CREATED'|translate }}</h4>
    </div>
    <div class="modal-body">
        <div>
            <!-- Client id -->
            <div class="form-group">
                <label class="control-label" for="id">Client id</label>
                <div class="input-group">
                    <input class="form-control" [readonly]="true" [value]="createdCredential.client_id" />
                    <span class="input-group-btn">
                        <button class="btn btn-default" (click)="clipboardService.copy(createdCredential.client_id)"
                            title="{{ 'GLOBAL.COPY_CLIPBOARD'|translate }}">
                            <span class="material-symbols-rounded">content_copy</span>
                        </button>
                    </span>
                </div>
            </div>
            <!-- Client Secret -->
            <div class="form-group">
                <label class="control-label" for="secret">Client secret</label>
                <div class="input-group">
                    <input class="form-control" [readonly]="true" [value]="createdCredential.client_secret" />
                    <span class="input-group-btn" title="{{ 'GLOBAL.COPY_CLIPBOARD'|translate }}">
                        <button (click)="clipboardService.copy(createdCredential.client_secret)"
                            class="btn btn-default">
                            <span class="material-symbols-rounded">content_copy</span>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #loading>
    <div class="loading">
        <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>
</ng-template>

<ng-template #noData>
    <!-- No data -->
    <div class="no-data no-data-sm">
        <i class="material-symbols-rounded">not_interested</i>
        {{ 'CLIENT_CREDENTIALS.NO_CREDENTIALS'|translate }}
    </div>
</ng-template>