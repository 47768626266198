<div class="flex-center-distributed m-b1">
    <div class="grow">
        <div class="summary" *ngIf="selected.length > 0">
            <span [innerHtml]="'LABELS.SELECTED'|translate: {quantity: selected.length}"></span>
            &mdash;
            <a class="small" (click)="selected = []">{{ 'GLOBAL.CLEAR_SELECTION'|translate }}</a>
            <ag-commercial-zones-manager [(ngModel)]="selected" [company]="company"></ag-commercial-zones-manager>
        </div>
    </div>
    <button type="button" [disabled]="!user?.mail_verified" class="btn btn-agree" (click)="managersModal.show()">
        <i class="material-symbols-rounded hidden-xs hidden-sm">send</i> {{ 'MANAGERS.APPLY_TO_BE_MANAGER'|translate }}
    </button>
</div>
<ng-container *ngIf="managers; else loading">
    <div *ngIf="managers.length > 0; else noData" class="horizontal-scroller">
        <div class="table-sticky-headers">
            <table class="table no-margin" [sortable]="managers" defaultSortBy="email" #tableManagers="tableSortable"
                AgMultiselection [(ngModel)]="selected">
                <thead>
                    <tr>
                        <th class="sap" *ngIf="managers?.length > 0">
                            <input type="checkbox" AgSelectall
                            id="select-all-toggle"
                            class="show-onhover no-margin" [disabled]="processing"
                            tooltip="{{ 'WORKING_ORDERS.SELECT_ALL'|translate }}" container="body"
                            [adaptivePosition]="false">
                        </th>
                        <th class="hidden-xs" sortBy="name">{{ 'TEAM_VIEW.NAME_LASTNAME'|translate }}</th>
                        <th sortBy="email">{{ 'TEAM_VIEW.EMAIL'|translate }}</th>
                        <th>{{ 'MANAGERS.REPRESENTS'|translate }}</th>
                        <th>{{ 'TEAM_VIEW.ROLE'|translate }}</th>
                        <th class="sap">
                            <abbr container="body" tooltip="{{ 'TEAM_VIEW.RESTRICT_VISIBILITY'|translate }}"
                                [adaptivePosition]="false">
                                <span class="material-symbols-rounded">visibility</span>
                            </abbr>
                        </th>
                        <th></th>
                        <th sortBy="invitation" class="sap">{{ 'GLOBAL.STATUS'|translate }}</th>
                        <th class="sap" *ngIf="account.is.commercial_with_users"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let member of tableManagers.collection" class="tr-align-center">
                        <!-- Select item checkbox -->
                        <td class="sap">
                            <input [disabled]="processing" *ngIf="!member.invitation && !iRepresent(member)"
                            [AgCheckbox]="member" type="checkbox"
                            name="chk-{{ member.id }}"
                            class="show-onhover no-margin">
                        </td>
                        <!-- Full name -->
                        <td class="hidden-xs">
                            <b [class.highlighted-text]="account.user.id === member.user?.id">{{ member.name }}</b>
                            <div class="micro">
                                <company-info [company]="member.parent.company" [compact]="true"></company-info>
                            </div>
                        </td>
                        <!-- Email -->
                        <td>
                            <div class="max-40 truncate">{{ member.email }}</div>
                        </td>
                        <!-- Represents -->
                        <td>
                            <company-info [company]="member.company ?  member.company : company" [activity]="false"
                                compact="true"></company-info>
                        </td>
                        <!-- Profile -->
                        <td>
                            <span
                                *ngIf="!account.is.commercial_with_users || !member.user || member.user?.id === user.id || member.company || member.invitation; else editInPlace">
                                {{ member.role.name }}
                            </span>
                            <ng-template #editInPlace>
                                <edit-in-place [(ngModel)]="member.role" [options]="roles"
                                    (onChange)="edit(member)"></edit-in-place>
                            </ng-template>
                        </td>
                        <!-- Comercial Zones [Checkbox] -->
                        <td class="sap">
                            <input
                                *ngIf="member.active && !member.is.admin && member.commercial_zones?.length > 0 && !member.invitation && !iRepresent(member)"
                                name="viewRestrictedToCommercialZones"
                                [(ngModel)]="member.settings.viewRestrictedToCommercialZones" type="checkbox"
                                [disabled]="processing" (change)="edit(member)" class="show-onhover no-margin">
                        </td>
                        <!-- Comercial Zones -->
                        <td>
                            <span *ngFor="let zone of member.commercial_zones" class="ag-label"
                            [attr.title]="zone.description"
                            [class.label-default]="!member.settings.viewRestrictedToCommercialZones"
                            [class.label-warning]="member.settings.viewRestrictedToCommercialZones">{{ zone.name }}</span>
                        </td>
                        <!-- Status -->
                        <td class="sap">
                            <span *ngIf="!member.invitation"
                                class="text-success">{{'MANAGERS.STATUS.ACCEPTED'|translate}}</span>
                            <ng-container *ngIf="member.invitation">
                                <button type="button" *ngIf="!iRepresent(member) else pending"
                                    class="btn btn-sm btn-agree flex-column"
                                    (click)="acceptInvitation(member.invitation.id)">
                                    {{'MANAGERS.STATUS.ACCEPT'|translate}}
                                </button>
                                <ng-template #pending>
                                    <span class="text-warning">{{'MANAGERS.STATUS.PENDING'|translate}}</span>
                                </ng-template>
                            </ng-container>
                        </td>
                        <!-- Delete button -->
                        <td class="sap">
                            <!-- 
                            <a *ngIf="member.user?.id !== user.id" title="{{ 'MANAGERS.DELETE_CONFIRM.MODAL_TITLE'|translate }}"
                            -->
                            <a *ngIf="account.is.commercial_with_users && !member.invitation"
                                title="{{ 'MANAGERS.DELETE_CONFIRM.MODAL_TITLE'|translate }}"
                                (onConfirm)="deleteManager(member)" [confirm-options]="{
                                title: 'MANAGERS.DELETE_CONFIRM.TITLE'|translate,
                                body: 'MANAGERS.DELETE_CONFIRM.BODY'|translate,
                                action: 'GLOBAL.YES_REMOVE'|translate
                            }" [confirm-close]="deletedMember">
                                <i class="material-symbols-rounded">cancel</i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>

<ng-template #loading>
    <div class="loading">
        <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>
</ng-template>

<ng-template #noData>
    <div class="no-data">
        <i class="material-symbols-rounded">not_interested</i>
        {{ 'MANAGERS.NO_MANAGERS'|translate }}
    </div>
</ng-template>

<ag-managers-request-modal #managersModal (submitted)="loadData()" [company]="company" [user]="user" [account]="account"
    [roles]="roles"></ag-managers-request-modal>