<ag-messenger
#messengerComponent
[company]="company"
[companies]="(company && order) ? [company, order.company]: []"
[entity-id]="[1,4].includes(order?.type.id) ? order.id : undefined"
entity="{{ order?.type.id|orderType }}"></ag-messenger>

<div class="ag-container order-view">
  <div class="ag-col-primary">
    <div [class.container]="!isAuction" [class.container-fluid]="isAuction">
      <div class="content-box">
        <ng-container *ngIf="order; else loading">
          <div *ngIf="order.order_status &&
          order.order_status.id !== 1; else checkOnHold"
          [class.ribbon-danger]="order.order_status.group.id === 2"
          [class.ribbon-info]="order.order_status.group.id === 1"
          class="ribbon ribbon-right ribbon-sm"><span>{{ order.order_status.name }}</span></div>
          <ng-template #checkOnHold>
            <div *ngIf="order?.on_hold" class="ribbon ribbon-right ribbon-warning ribbon-sm"><span>{{ 'ORDER_SUMMARY.PAUSED'|translate }}</span></div>
          </ng-template>
          <ol class="breadcrumb">
            <li *ngIf="environment.modules.market || (order.type.id === 2 || order.type.id === 5)">
              <a *ngIf="order.type.id === 1 || order.type.id === 4" routerLink="../../working-orders">{{ 'COMPANY.WORKING_ORDERS'|translate }}</a>
              <a *ngIf="order.type.id === 2 || order.type.id === 5" routerLink="../../preorders">{{ 'COMPANY.PREORDERS'|translate }}</a>
            </li>
            <li class="active">
              <scope-icon [entity]="order"></scope-icon>
              <span class="hidden-xs" *ngIf="!isAuction">
                <span *ngIf="order.type.id !== 2">{{ 'GLOBAL.ORDER'|translate }}</span>
                <span *ngIf="order.type.id === 2">{{ 'ORDER_VIEW.PREORDER'|translate }}</span>
              </span>
              <span class="hidden-xs" *ngIf="isAuction">
                <span *ngIf="order.type.id !== 5">{{ 'GLOBAL.AUCTION'|translate }}</span>
                <span *ngIf="order.type.id === 5">{{ 'AUCTION_VIEW.PREAUCTION'|translate }}</span>
              </span>
              #{{ orderId }}
            </li>
          </ol>
          <div class="status" *ngIf="
          !isAuction &&
          order.operation_type === 'compra' &&
          order.business_detail.quantity.type.id === 2 &&
          order.company.id === company.id &&
          order.order_status.group.id === 1 &&
          (order.totalBooked/order.business_detail.quantity.value) > .85">
            <alert type="danger">
              <i class="material-symbols-rounded blinking">warning</i>
              <b>{{ 'ORDER_VIEW.BOOKED_ALERT.TEXT_0'|translate }}</b>
              {{ 'ORDER_VIEW.BOOKED_ALERT.TEXT_1'|translate }}            
              <b>{{ order.totalBooked|number:'1.0-0':currentLang }} {{ order.business_detail.quantity.unit.abbrev }}</b>
              ({{ (100 * order.totalBooked/order.business_detail.quantity.value)|number:'1.0-0':currentLang }}%)
              {{ 'ORDER_VIEW.BOOKED_ALERT.TEXT_2'|translate }}
              <b>{{ order.business_detail.quantity.value|number:'1.0-0':currentLang }} {{ order.business_detail.quantity.unit.abbrev }}</b>
              {{ 'ORDER_VIEW.BOOKED_ALERT.TEXT_3'|translate }}
              <a title="{{ 'ORDER_VIEW.CANCEL_CONFIRM.TITLE'|translate }}"
              (onConfirm)="cancelOrder()"
              [confirm-options]="{
                title: ([1,4].includes(order.type.id) ? 'ORDER_VIEW.CANCEL_CONFIRM.TITLE_ORDER': 'ORDER_VIEW.CANCEL_CONFIRM.TITLE_PREORDER')|translate,
                body: ([1,4].includes(order.type.id) ? 'ORDER_VIEW.CANCEL_CONFIRM.BODY_ORDER': 'ORDER_VIEW.CANCEL_CONFIRM.BODY_PREORDER')|translate,
                action: 'GLOBAL.YES_WITHDRAW'|translate
              }"
              [confirm-close]="retiredOrder">{{ 'ORDER_VIEW.BOOKED_ALERT.WITHDRAW'|translate }}</a>
            </alert>
          </div>
          <div class="ag-header sticky">
            <div class="ag-header-title">
              <h3>
                <span class="label text-capitalize" [class.sell-bg]="order.operation_type === 'venta'" [class.buy-bg]="order.operation_type === 'compra'">{{ (order.operation_type === 'compra' ? 'GLOBAL.BID' : 'GLOBAL.SELL')|translate }}</span>
                {{ order.product.name || 'GLOBAL.NOT_AVAILABLE_ABBR'|translate }}
                <span>
                  <!-- *** Enable only for the owner -->
                  <div *ngIf="order.company.id === company.id" class="btn-group" dropdown container="body">
                    <button id="button-basic" title="{{ 'GLOBAL.ADVANCED_OPTIONS'|translate }}" dropdownToggle type="button" class="btn btn-link btn-sm dropdown-toggle" aria-controls="dropdown-basic">
                      <i class="material-symbols-rounded">build</i>
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                      <ng-container *ngIf="isAuction; else notAuction">
                        <!-- *** Enable only if the auction is active -->
                        <li *ngIf="
                        order.order_status.group.id === 1"
                        role="menuitem">
                          <a class="dropdown-item"
                          (onConfirm)="editOrder()"
                          [confirm-options]="{
                            title: 'AUCTION_VIEW.EDIT_CONFIRM.TITLE'|translate,
                            body: ([1,4].includes(order.type.id) ? 'AUCTION_VIEW.EDIT_CONFIRM.BODY_AUCTION': 'AUCTION_VIEW.EDIT_CONFIRM.BODY_PREAUCTION')|translate,
                            action: 'AUCTION_VIEW.EDIT_CONFIRM.CONFIRM_BUTTON'|translate
                          }"
                          [confirm-close]="heldOrder">{{ 'AUCTION_VIEW.EDIT'|translate }}</a>
                        </li>
                        <li role="menuitem">
                          <a routerLink="../../republish-auction/{{ order.id }}">{{ 'AUCTION_VIEW.REPUBLISH'|translate }}</a>
                        </li>
                      </ng-container>
                      <ng-template #notAuction>
                        <!-- *** Enable only if the order is active -->
                        <li *ngIf="order.order_status.group.id === 1"
                        role="menuitem">
                          <a class="dropdown-item"
                          (onConfirm)="editOrder()"
                          [confirm-options]="{
                            title: 'ORDER_VIEW.EDIT_CONFIRM.TITLE'|translate,
                            body: ([1,4].includes(order.type.id) ? 'ORDER_VIEW.EDIT_CONFIRM.BODY_ORDER': 'ORDER_VIEW.EDIT_CONFIRM.BODY_PREORDER')|translate,
                            action: 'ORDER_VIEW.EDIT_CONFIRM.CONFIRM_BUTTON'|translate
                          }"
                          [confirm-close]="heldOrder">{{ 'ORDER_VIEW.EDIT'|translate }}</a>
                        </li>
                        <li role="menuitem">
                          <a routerLink="../../republish-order/{{ order.id }}">{{ 'ORDER_VIEW.REPUBLISH'|translate }}</a>
                        </li>
                      </ng-template>
                    </ul>
                  </div>
                  <button *ngIf="order.company.id !== company.id &&
                    !isAuction &&
                    order.operation_type === 'compra' &&
                    company?.activity.buyer"
                    routerLink="../../republish-order/{{ order.id }}" title="{{ 'ORDER_VIEW.REPUBLISH'|translate }}" type="button" class="btn btn-link btn-sm">
                    <i class="material-symbols-rounded">file_copy</i>
                  </button>
                  <ag-assignees-manager
                  *ngIf="company?.id === order.company.id"
                  [entity]="order.entity"
                  [entity-id]="order.id"
                  [company]="company"></ag-assignees-manager>
                </span>
              </h3>
            </div>
            <div class="ag-header-actions">
              <div class="btn-toolbar">
                <div class="btn-group">
                  <ag-messenger-launcher
                  [messenger]="messengerComponent"></ag-messenger-launcher>

                  <ng-container *ngIf="order.company.id == company.id &&
                  order.order_status.group.id == 1">
                    <button type="button"
                    class="btn btn-link"
                    *ngIf="!isAuction"
                    (onConfirm)="cancelOrder()"
                    [confirm-options]="{
                      title: (order.type.id === 1? 'ORDER_VIEW.CANCEL_CONFIRM.TITLE_ORDER': 'ORDER_VIEW.CANCEL_CONFIRM.TITLE_PREORDER')|translate,
                      body: (order.type.id === 1? 'ORDER_VIEW.CANCEL_CONFIRM.BODY_ORDER': 'ORDER_VIEW.CANCEL_CONFIRM.BODY_PREORDER')|translate,
                      action: 'GLOBAL.YES_WITHDRAW'|translate
                    }"
                    [confirm-close]="retiredOrder">{{ 'GLOBAL.WITHDRAW'|translate }}</button>
                    
                    <button type="button"
                    class="btn btn-link"
                    *ngIf="isAuction"
                    [disabled]="auction_history && auction_history.length > 0"
                    (onConfirm)="cancelOrder()"
                    [confirm-options]="{
                      title: (order.type.id === 4? 'AUCTION_VIEW.CANCEL_CONFIRM.TITLE_AUCTION': 'AUCTION_VIEW.CANCEL_CONFIRM.TITLE_PREAUCTION')|translate,
                      body: (order.type.id === 4? 'AUCTION_VIEW.CANCEL_CONFIRM.BODY_AUCTION': 'AUCTION_VIEW.CANCEL_CONFIRM.BODY_PREAUCTION')|translate,
                      action: 'GLOBAL.YES_WITHDRAW'|translate
                    }"
                    [confirm-close]="retiredOrder">{{ 'GLOBAL.WITHDRAW'|translate }}</button>
                  </ng-container>
                </div>
  
                <div class="btn-group">
                  <button type="button"
                  *ngIf="order.type.id === 2"
                  title="{{ 'ORDER_VIEW.REJECT_CONFIRM.TITLE'|translate }}"
                  [disabled]="order.order_status.group.id !== 1 ||
                  order.broker_company?.id !== company.id ||
                  reviewing"
                  class="btn btn-default"
                  (onConfirm)="rejectOrder()"
                  [confirm-options]="{
                    title: (order.type.id === 1? 'ORDER_VIEW.REJECT_CONFIRM.TITLE_ORDER': 'ORDER_VIEW.REJECT_CONFIRM.TITLE_PREORDER')|translate,
                    body: '',
                    action: 'GLOBAL.YES_WITHDRAW'|translate
                  }"
                  [confirm-close]="rejectedOrder">
                    <i class="material-symbols-rounded hidden-xs hidden-sm">undo</i> {{ 'GLOBAL.REJECT'|translate }}
                  </button>
  
                  <ng-container *ngIf="company?.market.configuration.transactions.enabled">
                    <ng-container *ngIf="order.type.id === 1 && !isNegotiationRequestAvailable">
                      <button type="button"
                      class="btn btn-default"
                      routerLink="./create-counter-order"
                      [disabled]="
                      processing ||
                      (!company?.activity.buyer && order.operation_type === 'venta') ||
                      (!company?.activity.seller && order.operation_type === 'compra') ||
                      order.company.id === company.id ||
                      order.order_status.id !== 1 ||
                      order.on_hold ||
                      hasActiveNegotiations()">
                        <i class="material-symbols-rounded hidden-xs hidden-sm">swap_vert</i>
                        {{ 'GLOBAL.COUNTERORDER'|translate }}
                      </button>
  
                      <!-- Solo pueden anotarse órdenes abiertas -->
                      <button type="button"
                      class="btn btn-agree"
                      *ngIf="(order.operation_type === 'compra' && company?.market.configuration.order.pre_book.buy == false) || (order.operation_type === 'venta' && company?.market.configuration.order.pre_book.sell == false)"
                      (click)="book()"
                      [disabled]="processing ||
                      !company?.activity.buyer ||
                      order.company.id == company.id ||
                      order.order_status.id !== 1 ||
                      order.on_hold ||
                      hasActiveNegotiations()">
                        <i class="material-symbols-rounded hidden-xs hidden-sm">done</i>
                        {{ 'ORDER_VIEW.BOOK'|translate }}
                      </button>
  
                      <button type="button"
                      title="{{ 'ORDER_VIEW.PREBOOK'|translate }}" class="btn btn-agree"
                      *ngIf="(order.operation_type === 'compra' && company?.market.configuration.order.pre_book.buy == true) || (order.operation_type === 'venta' && company?.market.configuration.order.pre_book.sell == true)"
                      (click)="prebook()" [disabled]="processing ||
                      !company?.activity.seller ||
                      order.company.id === company.id ||
                      order.order_status.id !== 1 ||
                      order.on_hold ||
                      hasActiveNegotiations()">
                        <i class="material-symbols-rounded hidden-xs hidden-sm">done</i>
                        {{ 'ORDER_VIEW.PREBOOK'|translate }}
                      </button>
                    </ng-container>
                    <button type="button"
                    *ngIf="isNegotiationRequestAvailable"
                    class="btn btn-agree" 
                    (click)="negotiationRequest()"
                    [disabled]="processing ||
                    !company?.activity.buyer ||
                    order.company.id === company.id ||
                    order.order_status.id !== 1 ||
                    order.on_hold ||
                    hasActiveNegotiations()">
                      <i class="material-symbols-rounded hidden-xs hidden-sm">done</i>
                      {{ 'ORDER_VIEW.NEGOTIATION_REQUEST'|translate }}
                    </button>
                  </ng-container>
  
                  <button type="button"
                  *ngIf="order.type.id === 2 || order.type.id === 5"
                  [disabled]="order.order_status.group.id !== 1 ||
                  order.on_hold ||
                  !company?.activity.broker ||
                  reviewing"
                  title="{{ 'ORDER_VIEW.REVIEW'|translate }}"
                  class="btn btn-agree"
                  (click)="reviewOrder()">
                    <i class="material-symbols-rounded hidden-xs hidden-sm">edit</i>
                    {{ 'ORDER_VIEW.REVIEW'|translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row"
          *ngIf="order?.business_detail.sustainable">
            <div class="col-xs-12">
              <span
              class="ag-label label-success"><span class="glyphicon glyphicon-leaf" aria-hidden="true"></span> {{ 'ORDER_FORM.PRODUCT.SUSTAINABLE'|translate }}</span>
            </div>
          </div>
          <!-- Pre order status row -->
          <div class="row" *ngIf="order.type.id === 2">
            <div class="col-xs-12 status">
              <div class="alert status-info" role="alert" *ngIf="order.order_status.id === 2 && order.broker_company?.id !== company.id">
                <span *ngIf="order.type.id === 1" [innerHtml]="'ORDER_VIEW.STATUS.REVIEW_PENDING_BROKER_ORDER'|translate"></span>
                <span *ngIf="order.type.id === 2" [innerHtml]="'ORDER_VIEW.STATUS.REVIEW_PENDING_BROKER_PREORDER'|translate"></span>
              </div>
              <div class="alert status-warning" role="alert" *ngIf="order.order_status.id === 2 && order.broker_company?.id === company.id">
                <i class="material-symbols-rounded blinking">warning</i>
                <span *ngIf="order.type.id === 1" [innerHtml]="'ORDER_VIEW.STATUS.REVIEW_PENDING_ORDER'|translate"></span>
                <span *ngIf="order.type.id === 2" [innerHtml]="'ORDER_VIEW.STATUS.REVIEW_PENDING_PREORDER'|translate"></span>
              </div>
              <div class="alert status-danger" role="alert" *ngIf="order.order_status.id === 3">
                <span *ngIf="order.type.id === 1" [innerHtml]="'ORDER_VIEW.STATUS.REJECTED_ORDER'|translate"></span>
                <span *ngIf="order.type.id === 2" [innerHtml]="'ORDER_VIEW.STATUS.REJECTED_PREORDER'|translate"></span>
              </div>
              <div class="alert status-danger" role="alert" *ngIf="order.order_status.id === 4">
                <span *ngIf="order.type.id === 1" [innerHtml]="'ORDER_VIEW.STATUS.CANCELED_ORDER'|translate"></span>
                <span *ngIf="order.type.id === 2" [innerHtml]="'ORDER_VIEW.STATUS.CANCELED_PREORDER'|translate"></span>
              </div>
              <div class="alert status-success" role="alert" *ngIf="order.order_status.id === 5">
                <span *ngIf="order.type.id === 1" [innerHtml]="'ORDER_VIEW.STATUS.PUBLISHED_ORDER'|translate"></span>
                <span *ngIf="order.type.id === 2" [innerHtml]="'ORDER_VIEW.STATUS.PUBLISHED_PREORDER'|translate"></span>
              </div>
              <div class="alert status-danger" role="alert" *ngIf="order.order_status.id === 6">
                <span *ngIf="order.type.id === 1" [innerHtml]="'ORDER_VIEW.STATUS.EXPIRED_ORDER'|translate"></span>
                <span *ngIf="order.type.id === 2" [innerHtml]="'ORDER_VIEW.STATUS.EXPIRED_PREORDER'|translate"></span>
              </div>
            </div>
          </div>
          <counterparties [order]="order" [company]="company" (disclose)="disclose()"></counterparties>
  
          <proposal-detail
          [proposal]="order"
          [order]="order"
          [previousContract]="order.product_clauses"></proposal-detail>
  
          <div class="row" *ngIf="company?.market.configuration.order.expiration_date?.enabled && order.expiration_datetime">
            <!-- Enable to show the expiration date -->
            <div class="col-xs-12">
              <div class="small text-muted text-right">
                {{ 'ORDER_VIEW.EXPIRATION'|translate:{
                  day:order.expiration_datetime|date:'EEEE':undefined:currentLang,
                  date:order.expiration_datetime|date:'dd',
                  month:order.expiration_datetime|date:'MMMM':undefined:currentLang,
                  year:order.expiration_datetime|date:'y',
                  offset:company?.market.offset
                } }}
              </div>
            </div>
          </div>
  
          <negotiation-list *ngIf="order.type.id !== 2" [order]="order" [company]="company"></negotiation-list>
        </ng-container>
      </div>

      <div class="content-box" *ngIf="related_products?.length > 0">
        <h5>{{ 'ORDER_VIEW.RELATED_PRODUCTS'|translate }}</h5>
        <related-products [orders]="related_products" [company]="company"></related-products>
      </div>
    </div>
  </div>
  <div class="ag-col-secondary" *ngIf="isAuction">
    <div class="auction sticky">
      <div class="auction-container">
        <div class="auction-indicator auction-indicator-orange" *ngIf="isAuctionLive && !isAuctionEnded">
          <i class="material-symbols-rounded animated live">gavel</i>
          {{ 'AUCTION_VIEW.MODULE.LIVE'|translate }}
        </div>

        <div class="small text-muted">
          {{ (isAuctionLive ? 'AUCTION_VIEW.MODULE.TIME_LEFT' :
          (isAuctionEnded ? 'AUCTION_VIEW.MODULE.ENDED' :
          (order.order_status.id === 4 ? 'AUCTION_VIEW.MODULE.WITHDRAWN' : 'AUCTION_VIEW.MODULE.TIME_TO_START')))|translate }}
        </div>
        <div *ngIf="order.order_status.group.id === 1">
          <ag-countdown 
          *ngIf="!isAuctionEnded"
          [expired]="(isAuctionLive ? 'AUCTION_VIEW.MODULE.CLOSING' : 'AUCTION_VIEW.MODULE.STARTING')|translate"
          [end]="isAuctionLive ? (dateExtendTo || dateEnd) : dateFrom"></ag-countdown>

          <span class="text-muted" *ngIf="!isAuctionEnded">&nbsp;({{ (isAuctionLive ? (dateExtendTo || dateEnd) : order.auction.date_from) | date:'MMMM d, HH:mm':undefined:currentLang | titlecase }})</span>
          <span class="text-muted" *ngIf="isAuctionEnded">&nbsp;{{ (dateExtendTo || dateEnd) | date:'MMMM d, HH:mm':undefined:currentLang | titlecase }}</span>

          <div class="micro text-warning" *ngIf="dateExtendTo">{{ 'AUCTION_VIEW.MODULE.EXTENDED'|translate: {seconds: order.auction.extend_time} }}</div>
        </div>

        <table class="table table-condensed auction-info" *ngIf="order.company.id === company.id">
          <!-- Enrolled companies -->
          <tbody>
            <tr *ngIf="order.auction?.enrolled_companies?.length > 0">
              <td class="text-muted small text-right">
                {{ 'AUCTION_VIEW.MODULE.ENROLLED'|translate }}
              </td>
              <td>
                <a (click)="showEnrolledCompanies()">{{order.auction?.enrolled_companies?.length}}</a>
              </td>
            </tr>
            <tr *ngIf="order.auction?.enrolled_companies?.length === 0 && !isAuctionEnded && order.order_status.group.id === 1">
              <td class="text-muted small text-right">
                {{ 'AUCTION_VIEW.MODULE.NONE_ENROLLED'|translate }}
              </td>
            </tr>
          </tbody>
        </table>

        <div *ngIf="order.company.id !== company.id && !isAuctionLive && !isAuctionEnded && order.order_status.group.id === 1">
          <!-- Enroll module -->
          <hr>
          <div *ngIf="!isEnrolled">
            <button type="button" class="btn btn-agree btn-block" (click)="enrollToAuction()" [disabled]="enrolling">
              <i class="material-symbols-rounded">assignment_turned_in</i>
              {{ 'AUCTION_VIEW.MODULE.ENROLL_BUTTON'|translate }}
            </button>
            <span class="small help-block">
              {{ 'AUCTION_VIEW.MODULE.ENROLL_HELP'|translate }}
            </span>
          </div>
          <div *ngIf="isEnrolled">
            <div class="auction-indicator auction-indicator-agree">
              <table class="table table-condensed auction-info">
                <tr>
                  <td><i class="material-symbols-rounded animated live">assignment_turned_in</i></td>
                  <td class="small">{{ 'AUCTION_VIEW.MODULE.COMPANY_ENROLLED'|translate }}</td>
                </tr>
              </table>
            </div>
            <span class="small help-block">{{ 'AUCTION_VIEW.MODULE.COMPANY_ENROLLED_HELP'|translate }}</span>
          </div>
        </div>

        <div *ngIf="isAuctionLive || isAuctionEnded">
          <hr>
          <table class="table table-condensed auction-info" *ngIf="auction_history">
            <tbody>
              <tr>
                <td class="text-muted small text-right" *ngIf="!isAuctionEnded">{{ (order.operation_type === 'compra' ? 'AUCTION_VIEW.MODULE.CURRENT_OFFER' : 'AUCTION_VIEW.MODULE.CURRENT_BID')|translate }}:</td>
                <td class="text-muted small text-right" *ngIf="isAuctionEnded">{{ (order.operation_type === 'compra' ? 'AUCTION_VIEW.MODULE.LAST_OFFER' : 'AUCTION_VIEW.MODULE.LAST_BID')|translate }}:</td>
                <td class="truncate">
                  <b *ngIf="auction_history?.length > 0" class="bigger"><price [unit]="auction_history[0].price.unit.name" [value]="auction_history[0].price.value"></price></b>
                  <span *ngIf="auction_history?.length === 0 ">-</span>
                </td>
              </tr>
              <tr>
                <td class="text-muted small text-right">
                  {{ (order.operation_type === 'compra' ? 'AUCTION_VIEW.MODULE.OFFERS_HISTORY' : 'AUCTION_VIEW.MODULE.BIDS_HISTORY')|translate }}:
                </td>
                <td class="small">
                  <a (click)="showAuctionHistory()" *ngIf="auction_history?.length > 0">{{ auction_history?.length }} {{ (order.operation_type === 'compra' ? 'AUCTION_VIEW.MODULE.OFFERS' : 'AUCTION_VIEW.MODULE.BIDS')|translate }}</a>
                  <span *ngIf="auction_history?.length === 0">-</span>
                </td>
              </tr>
            </tbody>
          </table>

          <div *ngIf="order.company.id !== company.id && auction_history && !isWinning && !isAuctionEnded">
            <hr>
            <button type="button" class="btn btn-agree btn-block" (click)="placeBid()" [disabled]="placing_bid || isAuctionEnded">
              {{ (order.operation_type === 'compra' ? 'AUCTION_VIEW.MODULE.OFFER' : 'AUCTION_VIEW.MODULE.BID')|translate }}
              <price [unit]="order.business_detail.price.unit.name" [value]="getBidNumber()"></price>
            </button>
            <span class="small help-block">
              {{ 'AUCTION_VIEW.MODULE.STEP_HELP_0'|translate }}
              <b><price [unit]="order.business_detail.price.unit.name" [value]="order.auction.step"></price></b>
              {{ 'AUCTION_VIEW.MODULE.STEP_HELP_1'|translate }}
            </span>
            <table class="table table-condensed auction-info">
              <caption class="small">
                {{ (order.operation_type === 'compra' ? 'AUCTION_VIEW.MODULE.MINIMUM_OFFER' : 'AUCTION_VIEW.MODULE.MAXIMUM_BID')|translate }}
              </caption>
              <tbody>
                <tr>
                  <td>
                    <input class="form-control" type="number" [step]="order.auction?.step" [attr.min]="order.operation_type === 'compra' ? order.auction?.step : order.business_detail.price.value" [attr.max]="order.operation_type === 'compra' ? getBidNumber() : null" [(ngModel)]="bid_limit_value" name="bid_limit_value">
                  </td>
                  <td>
                    <button type="button" class="btn btn-agree" [disabled]="isLimitValueInvalid()" (click)="placeBidValue()">
                      {{ (order.operation_type === 'compra' ? 'AUCTION_VIEW.MODULE.PLACE_OFFER' : 'AUCTION_VIEW.MODULE.PLACE_BID')|translate }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <span class="small text-muted" *ngIf="order.operation_type === 'compra'">
              {{ 'AUCTION_VIEW.MODULE.PLACE_OFFER_HELP_0'|translate }}
              <b><price [unit]="order.business_detail.price.unit.name" [value]="order.auction.step"></price></b>
              {{ 'AUCTION_VIEW.MODULE.PLACE_OFFER_HELP_1'|translate }}
            </span>
            <span class="small text-muted" *ngIf="order.operation_type === 'venta'">
              {{ 'AUCTION_VIEW.MODULE.PLACE_BID_HELP_0'|translate }}
              <b><price [unit]="order.business_detail.price.unit.name" [value]="order.auction.step"></price></b>
              {{ 'AUCTION_VIEW.MODULE.PLACE_BID_HELP_1'|translate }}
            </span>
          </div>
          <div *ngIf="order.company.id !== company.id && isWinning">
            <hr>
            <div class="auction-indicator auction-indicator-agree">
              <table class="table table-condensed auction-info">
                <tr>
                  <td><i class="material-symbols-rounded">thumb_up</i></td>
                  <td *ngIf="!isAuctionEnded">{{ (order.operation_type === 'compra' ? 'AUCTION_VIEW.MODULE.WINNING_OFFER' : 'AUCTION_VIEW.MODULE.WINNING_BID')|translate }}</td>
                  <td *ngIf="isAuctionEnded">{{ 'AUCTION_VIEW.MODULE.WON_AUCTION' |translate }}</td>
                </tr>
              </table>
            </div>
            <span class="small help-block" *ngIf="!isAuctionEnded">
              <p *ngIf="automatic_offer"><!--  Display only if user has an automatic offer set  -->
                {{ (order.operation_type === 'compra' ? 'AUCTION_VIEW.MODULE.MINIMUM_OFFER_SET' : 'AUCTION_VIEW.MODULE.MAXIMUM_BID_SET')|translate }}
                <b><price [unit]="order.business_detail.price.unit.name" [value]="automatic_offer"></price></b>.
                <a (click)="cancelAutomaticAuction()">{{ 'GLOBAL.CANCEL'|translate }}<span *ngIf="canceling_automatic_offer"> ...</span></a>
              </p>
              {{ (order.operation_type === 'compra' ? 'AUCTION_VIEW.MODULE.WINNING_OFFER_HELP' : 'AUCTION_VIEW.MODULE.WINNING_BID_HELP')|translate }}
            </span>
          </div>
        </div>

      </div>

      <ng-template #enrolledCompanies>
        <!-- modal -->
        <div class="modal-header">
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">{{ 'AUCTION_VIEW.MODULE.ENROLLED'|translate }}</h4>
        </div>
        <div class="modal-body">
          <table class="table table-condensed">
            <thead>
              <tr>
                <th></th>
                <th>{{ 'GLOBAL.COMPANY'|translate }}</th>
                <th>{{ 'AUCTION_VIEW.MODULE.ENROLLED_MODAL.TIME'|translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let counterpart of order.auction?.enrolled_companies; let i = index" class="tr-align-center">
                <td class="sap">
                  <company-logo [company]="counterpart"></company-logo>
                </td>
                <td>
                  <company-info [company]="counterpart"></company-info>
                </td>
                <td *ngIf="counterpart.confirmation" class="sap">
                  <ag-timestamp [date]="counterpart.confirmation"></ag-timestamp>
                </td>
                <td class="text-muted" *ngIf="!counterpart.confirmation"><span class="warning-label">{{ 'AUCTION_VIEW.MODULE.ENROLLED_MODAL.INVITATION_SENT'|translate }}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
      <ng-template #auctionHistory>
        <!-- modal -->
        <div class="modal-header">
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">{{ (order.operation_type === 'compra' ? 'AUCTION_VIEW.MODULE.OFFERS_HISTORY' : 'AUCTION_VIEW.MODULE.BIDS_HISTORY')|translate }}</h4>
        </div>
        <div class="modal-body">
          <table class="table table-condensed">
            <thead>
              <tr>
                <th>{{ 'GLOBAL.ORDER'|translate }}</th>
                <th>{{ 'AUCTION_VIEW.MODULE.HISTORY.TIME'|translate }}</th>
                <th>{{ (order.operation_type === 'compra' ? 'AUCTION_VIEW.MODULE.HISTORY.OFFER' : 'AUCTION_VIEW.MODULE.HISTORY.BID')|translate }}</th>
                <th colspan="2">
                  <span *ngIf="order.company.id === company.id">{{ 'GLOBAL.COUNTERPART'|translate }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let bid of auction_history; let i = index" [class.success]="i === 0" class="tr-align-center">
                <th>{{ (i === 0) ? ( (isAuctionEnded ? 'AUCTION_VIEW.MODULE.HISTORY.WINNER' : 'AUCTION_VIEW.MODULE.HISTORY.CURRENT') | translate ) : '#' + (auction_history.length - i) }}</th>
                <td>
                  <ag-timestamp [date]="bid.date" showSeconds="true"></ag-timestamp>
                </td>
                <td class="truncate">
                  <b><price [unit]="bid.price.unit.name" [value]="bid.price.value"></price></b>
                </td>
                <td class="truncate">
                  <span class="text-success" *ngIf="order.company.id !== company.id && bid.company && bid.company.id === company.id">
                    <i class="material-symbols-rounded">arrow_left</i>
                    {{ (order.operation_type === 'compra' ? 'AUCTION_VIEW.MODULE.HISTORY.YOUR_OFFER' : 'AUCTION_VIEW.MODULE.HISTORY.YOUR_BID')|translate }}
                  </span>
                  <company-info *ngIf="order.company.id === company.id" [company]="bid.company" activity="false"></company-info>
                </td>
                <td class="small text-muted"><!-- TD for definition explanation -->
                  <span *ngIf="bid.tie_breaker">{{ (order.operation_type === 'compra' ? 'AUCTION_VIEW.MODULE.HISTORY.OFFER_OMITTED' : 'AUCTION_VIEW.MODULE.HISTORY.BID_OMITTED')|translate }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<!-- modal -->
<ng-template #bookingModal>
  <div class="modal-header">
    <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">
      {{ 'ORDER_VIEW.PREBOOK_MODAL.TITLE'|translate }}
      #{{ order.id }}
    </h4>
  </div>
  <div class="modal-body">
    <p>
      {{ 'ORDER_VIEW.PREBOOK_MODAL.BODY'|translate }}
    </p>
    <form id="booking-form" (ngSubmit)="bookingForm.valid && prebookDone()" #bookingForm="ngForm">
      <div class="form-group" hasError>
        <label for="quantity">{{ 'GLOBAL.QUANTITY'|translate }}</label>
        <input type="number" id="quantity" class="form-control" name="quantity" [(ngModel)]="offer.quantity"
        [min]="0"
        [max]="9999999"
        [readonly]="processing"
        required
        [modalFocus]="true">
        <error-list></error-list>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" [class.disabled]="processing" type="button" class="btn btn-link" data-dismiss="modal">
      {{ 'GLOBAL.CANCEL'|translate }}
    </button>
    <button [class.disabled]="processing" form="booking-form" type="submit" class="btn btn-agree">
      {{ 'ORDER_VIEW.PREBOOK_MODAL.CONFIRM_BUTTON'|translate }}
    </button>
  </div>
</ng-template>

<ng-template #unavailableModal>
  <!-- modal -->
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'ORDER_VIEW.UNAVAILABLE_MODAL.TITLE'|translate }}
    </h4>
  </div>
  <div class="modal-body">
    <p>
      {{ 'ORDER_VIEW.UNAVAILABLE_MODAL.BODY'|translate }}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button"
    class="btn btn-agree" (click)="modalRef.hide()" routerLink="../../working-orders">{{ 'GLOBAL.ACCEPT'|translate }}</button>
  </div>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>