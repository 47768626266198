import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { Subscription } from 'rxjs';

import { User } from '../../../../auth/models/user.model';
import { LoginService } from '../../../../auth/services/login.service';
import { ApiKey } from '../../../../models/api-key.model';
import { ApplicationsProxyService } from '../../../services/applications-proxy.service';
import { instanceToInstance, plainToInstance } from 'class-transformer';

@Component({
  selector: 'ag-api-keys',
  templateUrl: './api-keys.component.html',
  styleUrls: ['./api-keys.component.scss']
})
export class ApiKeysComponent implements OnInit, OnDestroy {

  @Input() private companyId: number;

  public typeArr: { [key: string]: 'password' | 'text' } = {};
  public apiKeys: ApiKey[];
  /** Current [[User]]. */
  public user: User;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;

  private subscriptions: Subscription[] = [];

  constructor(
    private applicationProxyService: ApplicationsProxyService,
    private loginService: LoginService,
    public clipboardService: ClipboardService
  ) { }

  ngOnInit(): void {
    this.loadData();

    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    }));
  }

  public create(): void {
    this.processing = true;
    this.subscriptions.push(
      this.applicationProxyService.create(this.companyId).subscribe(apiKey => {
        const copy = instanceToInstance(this.apiKeys);
        copy.push(plainToInstance(ApiKey, { ...apiKey, created_at: new Date() }));

        this.apiKeys = copy;
        this.processing = false;
      })
    );
  }

  private loadData(): void {
    this.subscriptions.push(
      this.applicationProxyService.get(this.companyId).subscribe(apiKeys => {
        this.apiKeys = apiKeys.filter(key => key.status !== 'DELETED');
      })
    );
  }

  public delete(key: ApiKey): void {
    this.processing = true;
    this.subscriptions.push(
      this.applicationProxyService.delete(this.companyId, key.api_key).subscribe(response => {
        const copy = this.apiKeys.filter(k => k.api_key != key.api_key);

        this.apiKeys = copy;
        this.processing = false;
      })
    );
  }

  public toggleType(key: string): void {
    this.typeArr[key] = this.typeArr[key] === 'password' ? 'text' : 'password';
  }

  public getType(key: string): string {
    return this.typeArr[key] ? this.typeArr[key] : this.typeArr[key] = 'password';
  }

  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
